import React, { Component } from 'react';
import Helmet from 'react-helmet';

import App from '../../../layout/App';
import Panel from '../../../core/Panel';

import './index.css';

export class TermsOfServicePage extends Component {
  static propTypes = {};

  render() {
    return (
      <App>
        <div className="component terms-of-service-page">
          <Helmet title="Terms of Service – ThinkReservations">
            <meta
              name="description"
              content="This page explains our terms of service, which you agree to when using thinkreservations.com web sites."
            />
          </Helmet>

          <Panel>
            <h1>ThinkReservations Terms of Service</h1>
            <p>Last modified: July 21, 2022</p>
            <h2>Acceptance of the Terms of Use</h2>
            <p>
              You and DeepThink LLC dba ThinkReservations (“Company,” “we,” or
              “us”) agree to the following terms and conditions (together with
              any documents they expressly incorporate by reference,
              collectively, “Terms of Use”). The Terms of Use govern your access
              to and use of thinkreservations.com (the “Website”), including any
              content, functionality, and services offered on or through
              thinkreservations.com (collectively, the “Services”), whether as a
              guest or a registered user.
            </p>
            <p>
              Please read the Terms of Use carefully before you start to use the
              Services. By using the Services, you accept and agree to these
              Terms of Use and our Privacy Policy, found at{' '}
              <a href="https://thinkreservations.com/privacy">
                https://thinkreservations.com/privacy
              </a>
              , incorporated herein by reference. If you do not want to agree to
              these Terms of Use or the Privacy Policy, do not access or use the
              Services.
            </p>
            <p>
              The Services are offered and available to users who are 18 years
              of age or older. By using the Services, you represent and warrant
              that you are of legal age to form a binding contract with the
              Company, meet all of the foregoing eligibility requirements, and,
              if you are acting on behalf of a company or entity, have are
              authorized to bind such entity to the Terms of Use. If you do not
              meet all of these requirements, you must not access or use the
              Services.
            </p>
            <h2>Changes to the Terms of Use</h2>
            <p>
              We may revise and update these Terms of Use from time to time in
              our sole discretion. All changes are effective immediately when we
              post them, and apply to all access to and use of the Services
              thereafter. However, any changes to the dispute resolution
              provisions set out in Governing Law and Jurisdiction will not
              apply to any disputes for which the parties have actual notice on
              or before the date the change is posted on the Website.
            </p>
            <p>
              Your continued use of the Services following the posting of
              revised Terms of Use means that you accept and agree to the
              changes.
            </p>
            <h2>Accessing the Services and Account Security</h2>
            <p>
              We reserve the right to withdraw or amend the Services, in our
              sole discretion without notice. From time to time, we may restrict
              access to some parts of the Services, or all of the Services, to
              users, including registered users.
            </p>
            <p>
              You are responsible for making all arrangements necessary for you
              to have access to the Services.
            </p>
            <p>
              To access the Services or some of the resources it offers, you may
              be asked to provide certain registration details or other
              information. It is a condition of your use of the Services that
              all the information you to us is correct, current, and complete.
            </p>
            <p>
              If you choose, or are provided with, a user name, password, or any
              other piece of information as part of our security procedures, you
              must treat such information as confidential, and you must not
              disclose it to any other person or entity. You also acknowledge
              that your account is personal to you and agree not to provide any
              other person with access to the Services or portions of it using
              your user name, password, or other security information. You agree
              to notify us immediately of any unauthorized access to or use of
              your user name or password or any other breach of security.
            </p>
            <p>
              We have the right to disable any user name, password, or other
              identifier, whether chosen by you or provided by us, at any time
              in our sole discretion for any or no reason, including if, in our
              opinion, you have violated any provision of these Terms of Use.
            </p>
            <h2>Intellectual Property Rights</h2>
            <p>
              The Services and their entire contents, features, and
              functionality (including but not limited to all information,
              software, text, displays, images, video, and audio, and the
              design, selection, and arrangement thereof) are owned by the
              Company, its licensors, or other providers of such material and
              are protected by United States and international copyright,
              trademark, patent, trade secret, and other intellectual property
              or proprietary rights laws.
            </p>
            <p>
              No right, title, or interest in or to the Services or any content
              on the Services is transferred to you, and all rights not
              expressly granted are reserved by the Company. Any use of the
              Services not expressly permitted by these Terms of Use is a breach
              of these Terms of Use and may violate copyright, trademark, and
              other laws.
            </p>
            <h2>Trademarks</h2>
            <p>
              The Company name, the terms ThinkReservations, the Company logo,
              and all related names, logos, product and service names, designs,
              and slogans are trademarks of the Company or its affiliates or
              licensors. You must not use such marks without the prior written
              permission of the Company. All other names, logos, product and
              service names, designs, and slogans on our Website are the
              trademarks of their respective owners.
            </p>
            <h2>Permitted Uses</h2>
            <p>
              You may use the Services only for lawful purposes and in
              accordance with these Terms of Use. You agree not to use the
              Services:
            </p>
            <ol>
              <li>
                In any way that violates any applicable federal, state, local,
                or international law or regulation (including, without
                limitation, any laws regarding the export of data or software to
                and from the US or other countries).
              </li>
              <li>
                For the purpose of exploiting, harming, or attempting to exploit
                or harm minors in any way by exposing them to inappropriate
                content, asking for personally identifiable information, or
                otherwise.
              </li>
              <li>
                To transmit, or procure the sending of, any advertising or
                promotional material, including any “junk mail,” “chain letter,”
                “spam,” or any other similar solicitation.
              </li>
              <li>
                To impersonate or attempt to impersonate the Company, a Company
                employee, another user, or any other person or entity
                (including, without limitation, by using email addresses
                associated with any of the foregoing).
              </li>
              <li>
                To engage in any other conduct that restricts or inhibits
                anyone’s use or enjoyment of the Services, or which, as
                determined by us, may harm the Company or users of the Services,
                or expose them to liability.
              </li>
              <li>
                In any manner that could disable, overburden, damage, or impair
                the site or interfere with any other party’s use of the
                Services, including their ability to engage in real time
                activities through the Services.
              </li>
            </ol>
            <p>Additionally, you agree not to:</p>
            <ol>
              <li>
                Introduce any viruses, Trojan horses, worms, logic bombs, or
                other material that is malicious or technologically harmful.
              </li>
              <li>
                Attempt to gain unauthorized access to, interfere with, damage,
                or disrupt any parts of the Services, the server on which the
                Services are hosted, or any server, computer, or database
                connected related to the Services.{' '}
              </li>
              <li>
                Attack the Services via a denial-of-service attack or a
                distributed denial-of-service attack.
              </li>
              <li>
                Otherwise attempt to interfere with the proper working of the
                Services.
              </li>
            </ol>
            <h2>User Contributions</h2>
            <p>
              The Services may contain user generated content (“User
              Contributions”). By providing any User Contribution to us, you
              grant us and our affiliates and service providers, and each of
              their and our respective licensees, successors, and assigns the
              right to use, reproduce, modify, perform, display, distribute, and
              otherwise disclose to third parties any such material for any
              purpose.
            </p>
            <p>
              You understand and acknowledge that you are responsible for any
              User Contributions you submit or contribute, and you, not the
              Company, have full responsibility for such content, including its
              legality, reliability, accuracy, and appropriateness.
            </p>
            <p>
              We are not responsible or liable to any third party for the
              content or accuracy of any User Contributions posted by you or any
              other user of the Services.
            </p>
            <p>
              We do not undertake to review all material before it is posted on
              the Services, and cannot ensure prompt removal of objectionable
              material after it has been posted. Accordingly, we assume no
              liability for any action or inaction regarding transmissions,
              communications, or content provided by any user or third party.
            </p>
            <h2>Cooperation with Law Enforcement</h2>
            <p>
              We have the right to cooperate fully with any law enforcement
              authorities or court order requesting or directing us to disclose
              the identity or other information of anyone using Services. YOU
              WAIVE AND HOLD HARMLESS THE COMPANY AND ITS AFFILIATES, LICENSEES,
              AND SERVICE PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY ACTION
              TAKEN BY ANY OF THE FOREGOING PARTIES DURING, OR TAKEN AS A
              CONSEQUENCE OF, INVESTIGATIONS BY EITHER SUCH PARTIES OR LAW
              ENFORCEMENT AUTHORITIES.
            </p>
            <h2>Reliance on Information Posted</h2>
            <p>
              The information presented on or through the Services is made
              available solely for general information purposes. We do not
              warrant the accuracy, completeness, or usefulness of this
              information. Any reliance you place on such information is
              strictly at your own risk. We disclaim all liability and
              responsibility arising from any reliance placed on such materials
              by you or any other visitor to the Services, or by anyone who may
              be informed of any of its contents.
            </p>
            <h2>Disclaimer of Warranties</h2>
            <p>
              YOUR USE OF THE SERVICES, ITS CONTENT, AND ANY SERVICES OR ITEMS
              OBTAINED THROUGH OUR WEBSITE IS AT YOUR OWN RISK. THE SERVICES,
              THEIR CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE
              SERVICES AND WEBSITE ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE”
              BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR
              IMPLIED. NEITHER THE COMPANY NOR ANY PERSON ASSOCIATED WITH THE
              COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE
              COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR
              AVAILABILITY OF THE SERVICES OR WEBSITE. WITHOUT LIMITING THE
              FOREGOING, NEITHER THE COMPANY NOR ANYONE ASSOCIATED WITH THE
              COMPANY REPRESENTS OR WARRANTS THAT THE SERVICES AND WEBSITE,
              THEIR CONTENTS, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE
              WEBSITE WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED,
              THAT DEFECTS WILL BE CORRECTED, OR THAT THE SERVICES OR ANY
              SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL OTHERWISE MEET
              YOUR NEEDS OR EXPECTATIONS.{' '}
            </p>
            <p>
              TO THE FULLEST EXTENT PROVIDED BY LAW, THE COMPANY HEREBY
              DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED,
              STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY
              WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR
              PARTICULAR PURPOSE.
            </p>
            <p>
              THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE
              EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
            </p>
            <h2>Limitation on Liability</h2>
            <p>
              TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL THE
              COLLECTIVE LIABILITY OF THE COMPANY AND ITS SUBSIDIARIES AND
              AFFILIATES, AND THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES,
              AGENTS, OFFICERS, AND DIRECTORS, TO ANY PARTY (REGARDLESS OF THE
              FORM OF ACTION, WHETHER IN CONTRACT, TORT, OR OTHERWISE) INCLUDE
              ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE
              DAMAGES OR EXCEED THE AMOUNT YOU HAVE PAID TO THE COMPANY IN THE
              LAST TWELVE MONTHS.
            </p>
            <p>
              The limitation of liability set out above does not apply to
              liability resulting from our gross negligence or willful
              misconduct.
            </p>
            <p>
              THE FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE
              EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
            </p>
            <h2>Indemnification</h2>
            <p>
              You agree to defend, indemnify, and hold harmless the Company, its
              affiliates, licensors, and service providers, and its and their
              respective officers, directors, employees, contractors, agents,
              licensors, suppliers, successors, and assigns from and against any
              claims, liabilities, damages, judgments, awards, losses, costs,
              expenses, or fees (including reasonable attorneys’ fees) arising
              out of or relating to your violation of these Terms of Use, your
              use of the Services, including, but not limited to, your User
              Contributions, or your use of any information obtained from the
              Services.
            </p>
            <h2>Governing Law and Jurisdiction</h2>
            <p>
              All matters relating to the Services and these Terms of Use, and
              any dispute or claim arising therefrom or related thereto (in each
              case, including non-contractual disputes or claims), shall be
              governed by and construed in accordance with the internal laws of
              the State of Washington without giving effect to any choice or
              conflict of law provision or rule (whether of the State of
              Washington or any other jurisdiction).
            </p>
            <p>
              Any legal suit, action, or proceeding arising out of, or related
              to, these Terms of Use or the Services shall be instituted
              exclusively in the federal courts of the United States or the
              courts of the State of Washington, in each case located in the
              City of Seattle, although we retain the right to bring any suit,
              action, or proceeding against you for breach of these Terms of Use
              in your country of residence or any other relevant country. You
              waive any and all objections to the exercise of jurisdiction over
              you by such courts and to venue in such courts.
            </p>
            <h2>Limitation on Time to File Claims</h2>
            <p>
              ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR
              RELATING TO THESE TERMS OF USE OR THE SERVICES MUST BE COMMENCED
              WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES; OTHERWISE,
              SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.
            </p>
            <h2>Waiver and Severability</h2>
            <p>
              No waiver by the Company of any term or condition set out in these
              Terms of Use shall be deemed a further or continuing waiver of
              such term or condition or a waiver of any other term or condition,
              and any failure of the Company to assert a right or provision
              under these Terms of Use shall not constitute a waiver of such
              right or provision.
            </p>
            <p>
              If any provision of these Terms of Use is held by a court or other
              tribunal of competent jurisdiction to be invalid, illegal, or
              unenforceable for any reason, such provision shall be eliminated
              or limited to the minimum extent such that the remaining
              provisions of the Terms of Use will continue in full force and
              effect.{' '}
            </p>
            <h2>Entire Agreement</h2>
            <p>
              The Terms of Use, our Privacy Policy, and if applicable, your
              service agreement with us constitute the sole and entire agreement
              between you and us regarding the Services and supersede all prior
              and contemporaneous understandings, agreements, representations,
              and warranties, both written and oral, regarding the Services. In
              the event of any conflict between the Terms of Use and your
              service agreement, the terms of your service agreement shall
              control.{' '}
            </p>
            <h2>Your Comments and Concerns</h2>
            <p>
              The Website and the Services are operated by DeepThink LLC dba
              ThinkReservations, 1700 7th Ave, Ste 116 PMB 173, Seattle, WA
              98101.
            </p>
            <p>
              All other feedback, comments, requests for technical support, and
              other communications relating to the Website or Services should be
              directed to: info@thinkreservations.com.
            </p>
            <h2>Reporting Claims of Copyright Infringement</h2>
            <p>
              If you believe that any User Contributions violate your copyright,
              please report your concerns in writing to our copyright agent
              designated below:
            </p>
            <p>
              DeepThink LLC dba ThinkReservations
              <br />
              Attn: Copyrights
              <br />
              1700 7th Ave, Ste 116 PMB 173
              <br />
              Seattle, WA 98101
              <br />
              877-736-4195
              <br />
              <a href="mailto:info@thinkreservations.com">
                info@thinkreservations.com
              </a>
            </p>
          </Panel>
        </div>
      </App>
    );
  }
}

export default TermsOfServicePage;
